import { bindActionCreators } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { ThunkMiddlewareOptions } from '../../types/thunk-extra';
import rootReducer, {
  setLanguage,
  setRegionalSettings,
  setUser,
  fetchAllSubscriptions,
  openDetails,
  closeDetails,
  demoSubscriptions,
  openCancelConfirmModal,
  closeCancelConfirmModal,
  confirmCancel,
  openUpmModal,
  closeUpmModal,
  setIdentityParams,
  setInstance,
  submitUpm,
  closeToast,
  setExperiments,
  setUpmFlowStatus,
  openPayNowModal,
  closePayNowModal,
} from './state';
import { TabState } from '../../types/settings';
import { PAID_PLANS_APP_DEF_ID, STORES_APP_DEF_ID } from './constants';
import { PricingPlanBenefitsServer } from '@wix/ambassador-pricing-plan-benefits-server/http';
import { WixEcommerceSubscriptions } from '@wix/ambassador-wix-ecommerce-subscriptions/http';
import { mySubscriptionsActionItemClick, mySubscriptionsOpened } from '@wix/bi-logger-subscriptions-bm/v2';
import { apiHeaders } from '../../services/headers';
import { IActionCreators } from '../../types/internal-types';
import { Experiments } from '../../Experiments';

interface IEvents {
  tabState: TabState;
}

const createController: CreateControllerFn = async ({ flowAPI }) => {
  const { experiments, controllerConfig } = flowAPI;
  const { setProps, wixCodeApi, appParams } = controllerConfig;
  const { isSSR, isEditor } = flowAPI.environment;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const baseUrl = isSSR ? 'https://www.wix.com' : '';
  const settingsEventsHandler = createEventHandler<IEvents>(publicData);
  const biLogger = flowAPI.bi!;
  const appInstanceId = appParams.instanceId || '00000000-0000-0000-0000-000000000000'; // zero guid is only for tests
  const { instance, appDefinitionId } = appParams;
  const headers = apiHeaders({ Authorization: instance });
  const sessionId = controllerConfig.platformAPIs.bi?.viewerSessionId;

  biLogger.updateDefaults({
    appInstanceId,
    origin: wixCodeApi.window.viewMode,
    sessionId,
  });

  return {
    async pageReady() {
      settingsEventsHandler.on('tabState', (tabState: TabState) => {
        setProps({ tabState });
      });

      settingsEventsHandler.onReset(() => {
        setProps({ tabState: TabState.Default });
      });

      const navigateToStores = ({ emptyState = false } = {}) => {
        biLogger.report(mySubscriptionsActionItemClick({ action: 'view_products', emptyState }));
        wixCodeApi.location.navigateTo?.({ pageId: 'shop' });
      };

      const getMetaSiteId = () => {
        return controllerConfig.platformAPIs.bi?.metaSiteId;
      };

      const getSiteOwnerId = () => {
        return controllerConfig.platformAPIs.bi?.ownerId;
      };
      const resolveLanguage = () => {
        const { translations } = flowAPI;
        const { i18n } = translations;
        const { language } = i18n;
        return language;
      };

      const isEnglishOrLocal = resolveLanguage() === 'en' || appInstanceId === 'test-instance-id';

      wixCodeApi.site.onInstanceChanged(({ instance: newInstance }) => {
        headers.Authorization = newInstance;
        store.dispatch(setInstance({ instance: newInstance }));
      }, appDefinitionId);

      const navigateToPricingPlans =
        () =>
        async ({ emptyState = false } = {}) => {
          biLogger.report(mySubscriptionsActionItemClick({ action: 'view_plans', emptyState }));
          const structure = await wixCodeApi.site.getSiteStructure({ includePageId: true });
          const pricingPlansPage = structure.pages.filter(
            ({ applicationId }: any) => applicationId === PAID_PLANS_APP_DEF_ID,
          )?.[0];
          if (pricingPlansPage) {
            wixCodeApi.location.navigateTo?.({ pageId: pricingPlansPage.id });
          }
        };

      // This is a hacky way to know if an app is installed. Look for something better.
      const ppInstalled = wixCodeApi.site.getAppToken ? !!wixCodeApi.site.getAppToken(PAID_PLANS_APP_DEF_ID) : false;
      const storesInstalled = wixCodeApi.site.getAppToken ? !!wixCodeApi.site.getAppToken(STORES_APP_DEF_ID) : false;

      const store = configureStore({
        reducer: rootReducer,
        middleware: [
          ...getDefaultMiddleware<any, ThunkMiddlewareOptions>({
            serializableCheck: {
              isSerializable: () => true,
            },
            thunk: {
              extraArgument: {
                baseUrl,
                biLogger,
                fedops: flowAPI.fedops,
                httpClient: flowAPI.httpClient,
                experiments: flowAPI.experiments,
                translations: flowAPI.translations,
                errorMonitor: flowAPI.errorMonitor,
                ecomSubscriptionsService: WixEcommerceSubscriptions(
                  `${baseUrl}/_api/subscriptions-server`,
                ).SubscriptionsApi(),
                memberBenefitsService: PricingPlanBenefitsServer(
                  `${baseUrl}/_api/pricing-plan-benefits`,
                ).MemberBenefits(),
              },
            },
          }),
        ],
      });

      store.subscribe(() => {
        setProps({ state: store.getState() });
      });

      const actionCreators: IActionCreators = {
        fetchAllSubscriptions,
        openDetails,
        closeDetails,
        navigateToStores,
        openCancelConfirmModal,
        closeCancelConfirmModal,
        confirmCancel,
        navigateToPricingPlans,
        closeUpmModal,
        openUpmModal,
        closePayNowModal,
        openPayNowModal,
        submitUpm,
        closeToast,
        setUpmFlowStatus,
      };

      setProps({
        methods: bindActionCreators<any, any>(actionCreators, store.dispatch),
        state: store.getState(),
        tabState: TabState.Default,
        ppInstalled,
        storesInstalled,
        fitToContentHeight: true,
      });
      store.dispatch(setLanguage(resolveLanguage()));
      store.dispatch(setRegionalSettings(wixCodeApi.site.regionalSettings || wixCodeApi.site.language));
      store.dispatch(
        setUser({
          id: wixCodeApi.user.currentUser.id,
          instance: wixCodeApi.user.currentUser.instance || appParams.instance,
          loggedIn: wixCodeApi.user.currentUser.loggedIn,
        }),
      );
      store.dispatch(
        setIdentityParams({
          appInstanceId,
          msid: getMetaSiteId(),
          instance,
          siteOwnerId: getSiteOwnerId(),
          appDefinitionId, // appdefid of subscriptions-tpa
          sessionId,
          visitorId: controllerConfig.platformAPIs.bi?.visitorId,
        }),
      );
      store.dispatch(
        setExperiments({
          ...experiments.all(),
          [Experiments.SHOW_LAST_NEXT_CHARGE]:
            experiments.enabled(Experiments.SHOW_LAST_NEXT_CHARGE) && isEnglishOrLocal, // workaround to open feature english only in editor flow
          [Experiments.ENABLE_UPDATE_PAYMENT_METHOD]:
            experiments.enabled(Experiments.ENABLE_UPDATE_PAYMENT_METHOD) && isEnglishOrLocal,
          [Experiments.ENABLE_PAY_NOW]: experiments.enabled(Experiments.ENABLE_PAY_NOW) && isEnglishOrLocal,
        }),
      );
      if (isEditor) {
        return store.dispatch<any>(demoSubscriptions());
      }

      wixCodeApi.user.onLogin(() => {
        store.dispatch(
          setUser({
            id: wixCodeApi.user.currentUser.id,
            instance: wixCodeApi.user.currentUser.instance || appParams.instance,
            loggedIn: wixCodeApi.user.currentUser.loggedIn,
          }),
        );
        store.dispatch<any>(fetchAllSubscriptions());
      });

      return store
        .dispatch<any>(fetchAllSubscriptions())
        .then(async (action: any) => {
          const totalSubscriptions = action?.payload ? (action?.payload as any[])?.length : 0;
          if (totalSubscriptions === 1) {
            await store.dispatch<any>(openDetails(action?.payload[0].id));
          }
          // @todo add error reporting to catch why no action
          biLogger.report(
            mySubscriptionsOpened({
              totalSubscriptions,
              referralInfo: 'null',
              tabName: 'null',
            }),
          );
        })
        .catch((e: Error) => {
          biLogger.report(
            mySubscriptionsOpened({
              totalSubscriptions: 0,
              referralInfo: 'null',
              tabName: 'null',
            }),
          );
          flowAPI.reportError(e);
        });
    },
    updateConfig($w, config) {
      const updatedPublicData = config.publicData.COMPONENT || {};
      settingsEventsHandler.notify(updatedPublicData);
    },
  };
};

export default createController;
